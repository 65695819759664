import { differenceInSeconds } from "date-fns";
import { events } from "./constants";
import { event } from "./gtag";

const capturedEvents = {};

const updateCapturedEvents = (action, data = {}) => {
  if (!action) return;
  capturedEvents[action] = {
    ...data,
    timestamp: new Date(),
  };
};

const eventIsDuplicated = (action, data) => {
  // add exception for Match_Quiz_CTA_Clicked.
  // because it's such a critical event, we don't care if it gets duplicated
  if (action === "Match_Quiz_CTA_Clicked") return false;

  if (!data?.value) return false;
  if (typeof data?.value !== "string") return false;
  if (!capturedEvents[action]?.value) return false;
  if (capturedEvents[action].value !== data.value) return false;
  if (!capturedEvents[action].timestamp) return false;

  // if the same event with the same value was sent within the last 10 seconds,
  // pevent the duplicate event from being sent
  return differenceInSeconds(new Date(), capturedEvents[action].timestamp) < 10;
};

export const captureEvent = ({ action, ...data }) => {
  if (eventIsDuplicated(action, data)) {
    return console.log(
      "preventing duplicate event call",
      capturedEvents[action]
    );
  }
  updateCapturedEvents(action, data);

  console.log("captureEvent", { action, ...data });
  event({ action, ...data });

  if (typeof window?.analytics?.track === "undefined") return;
  console.log("sending event to segment");
  window.analytics.track(events[action] || action, data);
};
