import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

export const trackTTQEvent = (event, _content = {}, idName) => {
  if (typeof window?.ttq?.instance === "undefined") return;
  const eventIdName = `ttq_event_${idName || event}`;

  let event_id = Cookies.get(eventIdName);

  if (!event_id) {
    event_id = uuidv4();
    Cookies.set(eventIdName, event_id);
  }

  const content = { env: process.env.NEXT_PUBLIC_ENVIRONMENT, ..._content };
  console.log("trackTTQEvent", { event, content, eventIdName, event_id });

  window?.ttq
    ?.instance?.("C97L7M3C77U37LFVKAFG")
    ?.track(event, content, { event_id });
};
