import React from "react";
import { useQuery } from "react-query";
import { fetcher } from "~/lib/misc";

export const getTeams =
  (teamID = "") =>
  async () => {
    const response = await fetcher(
      process.env.NEXT_PUBLIC_API_BASE_URL + `/bootcamps/` + teamID,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  };

export const useTeams = (teamID) => {
  const {
    data = [],
    error,
    isLoading,
  } = useQuery(`/teams`, getTeams(), {
    refetchOnWindowFocus: false,
  });

  const teams = React.useMemo(
    () => data?.filter?.((n) => n?.status === "active") || [],
    [data]
  );

  const [activeTeam] = React.useMemo(
    () => (teamID ? teams.filter(({ id }) => teamID === id) : teams),
    [teamID, teams]
  );

  const getTeamById = React.useCallback(
    (ID) => {
      if (ID) {
        return teams.filter(({ id }) => ID === id)?.[0];
      }
    },
    [teams]
  );

  return {
    teams,
    teamID,
    activeTeam,
    getTeamById,
    error,
    isLoading,
  };
};

export const useTeam = (teamID, props = {}) => {
  const {
    data: team = {},
    error,
    isLoading,
  } = useQuery(`/team/${teamID}`, getTeams(teamID), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    team,
    teamID,
    error,
    isLoading,
  };
};
