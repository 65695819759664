import { useEffect, useRef, useState } from "react";
import { getTeams } from "~/hooks/useTeams";
import slugify from "slugify";

export const fetcher = (...args) => fetch(...args).then((res) => res.json());

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function useOutsideAlerter(ref, handleClick = () => null, props = []) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...props]);
}

export function getUrlWithUtms(url = "", utmContentAppend = "") {
  if (!url) return "";
  if (typeof window === "undefined") return "";

  const params = new URLSearchParams(window.location.search);
  const keys = [
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_campaign",
    "utm_content",
    "savvy_flow_version",
    "promoCode",
    "variant",
  ];

  keys.map((key) => {
    const value = params.get(key);
    if (key === "utm_content" && params.get("workoutId")) {
      return url.searchParams.set(
        key,
        `web_wo_${params.get("workoutId")}${utmContentAppend}`
      );
    }
    if (!!value) {
      url.searchParams.set(key, value);
    }
  });

  return url;
}

export const updateUTMParams = (initialURL, utmContentAppend) => {
  let url;

  try {
    url = new URL(initialURL);
  } catch (_) {
    if ("undefined" !== typeof window) {
      url = new URL(window.location.origin + initialURL);
    }
  }
  return getUrlWithUtms(url, utmContentAppend);
};

export function createDictionary(data, key = "id") {
  if (!data?.length) return {};

  const dictionary = {};
  data.map((n) => {
    dictionary[n[key]] = n;
  });

  return dictionary;
}

export function useLockBodyScroll() {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export const getTeamFromSlug = async (slug) => {
  const isTeamID = slug.length === 36;
  const getData = getTeams();
  const response = await getData();

  if (isTeamID) {
    // handle case where team id is passed in the url instead of the slug
    const [team] = response.filter(({ id }) => id === slug);
    return team;
  }
  const [team] = response.filter(
    ({ name }) => slugify(name, { lower: true }) === slug
  );

  return team;
};
