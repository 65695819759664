import clsx from "clsx";
import { motion } from "framer-motion";
import Link from "next/link";
import React, { useMemo } from "react";
import { accessVariant } from "~/components/text-node";
import { captureEvent } from "~/lib/captureEvent";
import { updateUTMParams } from "~/lib/misc";
import { trackTTQEvent } from "~/lib/trackTTQEvent";
import { useExperiment } from "~/state-containers/experiment";

function checkIsExternal(string) {
  return /(http(s?)):\/\//i.test(string);
}

export const LinkAction = ({ children, event, href, ...props }) => {
  const handleClick = () => {
    captureEvent({ ...event, label: children });
  };

  return (
    <Link href={href}>
      <a onClick={handleClick} {...props}>
        {children}
      </a>
    </Link>
  );
};

export const Action = ({
  at,
  defaultClasses = "text-sm md:text-base rounded-full cta",
  className,
  event,
  ...props
}) => {
  const { variant } = useExperiment();
  const accessedVariant = accessVariant(at, variant);

  const sendEvent = () => {
    trackTTQEvent("ClickButton");

    captureEvent({
      category: at,
      action: "find_my_plan_cta_clicked",
      label: accessedVariant.label,
      ...event,
    });
  };

  const handleClick = (e) => {
    sendEvent();
    props?.onClick?.();
  };

  const href = useMemo(() => {
    if (accessedVariant.link) {
      return accessedVariant.link;
    }
    if (accessedVariant.href) {
      return updateUTMParams(accessedVariant.href).href;
    }
  }, [accessedVariant]);

  if (typeof window === "undefined") return null;

  return (
    // <Link href={href}>
    <motion.a
      {...props}
      key={href}
      href={href}
      target={
        accessedVariant.target
          ? accessedVariant.target
          : accessedVariant.href
          ? "_self"
          : ""
      }
      rel="noreferrer"
      onClick={handleClick}
      aria-label={accessedVariant.label}
      className={clsx(
        "relative inline-block cursor-pointer",
        defaultClasses,
        className
      )}
      whileHover={{ scale: 1.025 }}
      whileTap={{ scale: 0.975 }}
    >
      {accessedVariant.label}
    </motion.a>
    // </Link>
  );
};
