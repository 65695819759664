import Cookies from "js-cookie";
import React, { useMemo } from "react";
import { createContainer } from "unstated-next";
import { HOME_EXPERIMENT_ID } from "~/lib/constants";

const useContainer = (initialState = "") => {
  return {
    variant: initialState,
  };
};

const { useContainer: useExperiment, Provider: ExperimentProvider } =
  createContainer(useContainer);

export { useExperiment, ExperimentProvider };
