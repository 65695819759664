import { MenuIcon, XIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import React from "react";
import { useLockBodyScroll, useToggle } from "react-use";
import { updateUTMParams } from "~/lib/misc";
import { useIsMobile } from "~/lib/use-breakpoint";
import { useApp } from "~/state-containers/app";
import { Action, LinkAction } from "../action";
import { Text } from "../text-node";

export const Logo = ({ src = "/assets/logo/white.svg" }) => {
  const isMobile = useIsMobile();
  return (
    <img
      className="self-start"
      src={src}
      alt="Ladder Logo"
      width={632 * (isMobile ? 0.15 : 0.2)}
      height={104 * (isMobile ? 0.15 : 0.2)}
    />
  );
};

const Nav = ({
  toggleMobileMenuOpen,
  mobileMenuOpen,
  showAdditionalActions = true,
  showWWOD,
}) => {
  const { isHeroCTAInView } = useApp();
  const isMobile = useIsMobile();

  return (
    <motion.nav className="sticky top-0 z-50 flex items-center justify-between w-full px-4 py-2 text-white bg-black lg:px-12 bg-opacity-60 backdrop-blur-xl">
      <Link href="/">
        <a className="center">
          <Logo />
        </a>
      </Link>
      <motion.div
        initial={{ opacity: isMobile ? 1 : 0 }}
        animate={{ opacity: isMobile ? 1 : isHeroCTAInView ? 0 : 1 }}
        className={clsx("flex items-center space-x-4 text-white")}
      >
        <div className="items-center hidden space-x-6 text-sm lg:flex">
          {showWWOD ? (
            <LinkAction
              event={{ action: "WWOD_page__clicked", category: "nav" }}
              href={updateUTMParams("/workout/today")}
              className="uppercase hover:opacity-80"
            >
              <b className="text-volt">Free</b> Workout Of The Day
            </LinkAction>
          ) : null}
          {showAdditionalActions ? (
            <>
              <LinkAction
                event={{ action: "ladder_shop_clicked", category: "nav" }}
                href="https://shop.joinladder.com/"
                target="_blank"
                rel="noreferrer"
                className="uppercase hover:opacity-80"
              >
                Shop
              </LinkAction>
              <LinkAction
                event={{ action: "ladder_careers_clicked", category: "nav" }}
                href="https://ladder.breezy.hr"
                target="_blank"
                rel="noreferrer"
                className="uppercase hover:opacity-80"
              >
                Careers
              </LinkAction>
            </>
          ) : null}
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isHeroCTAInView ? 0 : 1 }}
        >
          <Action
            at="nav.cta"
            className="flex px-4 py-2 text-xs text-black rounded-full md:text-sm lg:px-6 cta bg-volt"
          />
        </motion.div>
        {showAdditionalActions ? (
          <button
            aria-label={mobileMenuOpen ? "close menu" : "open menu"}
            onClick={toggleMobileMenuOpen}
            className="flex lg:hidden"
          >
            {mobileMenuOpen ? (
              <XIcon className="w-6 h-6 text-white" />
            ) : (
              <MenuIcon className="w-6 h-6 text-white" />
            )}
          </button>
        ) : null}
      </motion.div>
    </motion.nav>
  );
};

const MobileMenu = ({
  toggleMobileMenuOpen,
  mobileMenuOpen,
  showAdditionalActions = true,
  showWWOD,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -25 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed inset-0 z-50 w-screen h-screen text-white bg-black bg-opacity-80 backdrop-blur-xl"
    >
      <div className="px-4 py-3 text-xs text-center border-b border-gray-600">
        <Text as="span" at="notice.home" />
      </div>
      <motion.nav className="flex items-center justify-between w-full px-4 py-2 text-white">
        <div style={{ width: 95 }} className="flex justify-start">
          <Logo />
        </div>
        <button
          aria-label={mobileMenuOpen ? "close menu" : "open menu"}
          onClick={toggleMobileMenuOpen}
          className="flex lg:hidden"
        >
          {mobileMenuOpen ? (
            <XIcon className="w-6 h-6 text-white" />
          ) : (
            <MenuIcon className="w-6 h-6 text-white" />
          )}
        </button>
      </motion.nav>
      <div className="px-2 pt-12 mx-2 border-t border-gray-600">
        <div className="flex flex-col items-start space-y-6 text-xl text-left cta">
          {showWWOD ? (
            <LinkAction
              event={{ action: "WWOD_page__clicked", category: "nav" }}
              href={updateUTMParams("/workout/today")}
              className="uppercase hover:opacity-80"
            >
              <b>Free</b> Workout Of The Day
            </LinkAction>
          ) : null}
          <LinkAction
            event={{ action: "pricing_page__clicked", category: "nav" }}
            className="uppercase hover:opacity-80"
            href="/pricing"
          >
            Pricing
          </LinkAction>
          <LinkAction
            event={{ action: "ladder_shop_clicked", category: "nav" }}
            href="https://shop.joinladder.com/"
            target="_blank"
            rel="noreferrer"
            className="uppercase hover:opacity-80"
          >
            Shop
          </LinkAction>
          <LinkAction
            event={{ action: "ladder_careers_clicked", category: "nav" }}
            href="https://ladder.breezy.hr"
            target="_blank"
            rel="noreferrer"
            className="uppercase hover:opacity-80"
          >
            Careers
          </LinkAction>

          <Action
            at="nav.cta"
            defaultClasses="text-xl"
            className="text-left uppercase text-volt"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default function NavContainer(props = {}) {
  const [mobileMenuOpen, toggleMobileMenuOpen] = useToggle(false);

  useLockBodyScroll(mobileMenuOpen);

  return (
    <>
      <Nav
        mobileMenuOpen={mobileMenuOpen}
        toggleMobileMenuOpen={toggleMobileMenuOpen}
        {...props}
      />
      <AnimatePresence>
        {mobileMenuOpen ? (
          <MobileMenu
            mobileMenuOpen={mobileMenuOpen}
            toggleMobileMenuOpen={toggleMobileMenuOpen}
            {...props}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
}
